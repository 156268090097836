import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1072.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1072.000000) scale(0.100000,-0.100000)" >
				<path d="M5178 10513 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5010 10506 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z"/>
<path d="M5750 10500 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M4938 10493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5848 10493 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4728 10463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4560 10446 c4 -4 16 -4 26 2 16 9 14 10 -8 9 -15 -1 -23 -6 -18 -11z"/>
<path d="M6235 10450 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M4380 10420 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M6408 10413 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6458 10403 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4068 10343 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6718 10343 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3988 10313 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3805 10270 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
<path d="M4978 10264 c-1149 -104 -2204 -594 -3014 -1399 -608 -604 -1026
-1315 -1265 -2150 -38 -132 -50 -183 -99 -430 -73 -360 -99 -870 -65 -1275 40
-490 147 -945 332 -1420 47 -119 227 -497 280 -585 16 -27 49 -84 73 -125 41
-70 198 -309 210 -320 3 -3 17 -21 30 -40 46 -67 266 -335 344 -420 200 -215
539 -518 716 -640 19 -13 37 -27 40 -30 3 -3 57 -40 120 -82 666 -441 1385
-704 2183 -799 244 -29 797 -32 1037 -6 736 82 1414 309 2040 685 79 48 396
263 427 291 10 9 39 32 63 51 74 57 80 62 164 134 96 83 431 416 504 501 642
756 1034 1636 1151 2585 68 548 49 1042 -59 1581 -84 416 -211 790 -400 1174
-268 547 -620 1020 -1066 1435 -720 670 -1650 1114 -2629 1254 -319 46 -804
59 -1117 30z m-1444 -2366 c14 -19 16 -66 16 -309 0 -275 -1 -287 -21 -313
-24 -31 -52 -33 -79 -6 -19 19 -20 33 -20 313 0 324 2 337 60 337 18 0 34 -8
44 -22z m-170 -110 c13 -19 16 -61 16 -262 0 -232 -1 -239 -22 -257 -39 -35
-80 -17 -93 40 -12 54 1 469 15 486 19 23 66 19 84 -7z m905 -57 c11 -32 21
-63 21 -70 0 -17 49 -32 106 -32 81 0 83 -10 10 -68 -62 -49 -78 -72 -57 -86
6 -3 13 -27 16 -53 4 -26 9 -56 12 -67 6 -20 5 -20 -16 -1 -11 11 -21 15 -21
10 0 -5 -4 -4 -8 2 -9 14 -88 64 -101 64 -5 0 -40 -20 -77 -45 -36 -25 -68
-45 -70 -45 -5 0 3 33 25 98 16 47 19 67 11 82 -6 11 -14 20 -18 20 -15 0
-106 90 -92 90 167 6 174 9 204 106 9 30 21 54 25 54 5 0 18 -26 30 -59z m507
44 c-3 -8 -2 -15 3 -15 4 0 13 -19 20 -42 23 -79 34 -88 104 -89 68 0 108 -11
89 -23 -7 -4 -16 -4 -22 -1 -5 3 -8 0 -5 -7 2 -7 -16 -28 -42 -47 l-45 -33 6
-60 c4 -33 11 -70 16 -83 5 -13 6 -25 4 -28 -6 -6 -97 52 -102 65 -11 24 -105
-5 -153 -48 -22 -20 -43 -18 -34 4 4 9 14 46 23 82 l17 65 -46 40 c-25 22 -54
45 -65 51 -31 19 -9 27 72 28 l77 1 24 44 c13 24 23 48 23 52 0 19 21 59 31
59 6 0 9 -7 5 -15z m530 -1 c1 -19 17 -69 24 -79 4 -5 10 -22 14 -37 6 -27 8
-28 79 -28 62 0 112 -12 97 -22 -134 -98 -134 -99 -101 -198 10 -30 17 -58 14
-62 -5 -9 -38 9 -71 39 -50 43 -70 49 -107 29 -19 -10 -43 -27 -55 -38 -11
-10 -24 -17 -29 -13 -5 3 -11 -1 -14 -7 -2 -7 -7 -10 -11 -7 -4 4 2 39 14 77
25 82 22 91 -44 142 -65 50 -60 60 28 60 47 0 77 5 84 13 5 6 20 43 32 80 13
37 28 66 34 64 6 -2 11 -8 12 -13z m534 -26 c7 -24 17 -47 22 -52 4 -6 8 -18
8 -28 0 -26 33 -39 106 -42 36 -2 67 -6 70 -9 3 -3 -20 -24 -51 -47 -31 -23
-59 -48 -62 -56 -3 -8 1 -50 10 -92 8 -42 14 -79 12 -81 -2 -1 -21 11 -42 27
-68 55 -91 63 -132 46 -19 -8 -50 -26 -69 -40 -18 -14 -36 -24 -39 -20 -8 7 6
76 15 76 4 0 8 8 8 18 1 9 4 29 9 45 6 21 4 27 -9 27 -9 0 -16 6 -16 13 0 6
-21 27 -45 46 -25 18 -45 37 -45 42 0 4 35 9 78 11 69 3 78 5 87 26 5 12 13
22 17 22 5 0 8 9 8 19 0 11 5 23 10 26 6 4 10 12 9 18 -3 16 11 47 20 47 5 0
14 -19 21 -42z m520 27 c0 -9 9 -35 20 -57 11 -23 20 -47 20 -54 0 -25 31 -36
99 -35 39 0 72 -3 75 -8 3 -5 -11 -21 -32 -36 -70 -52 -83 -71 -81 -118 1 -23
5 -46 9 -52 9 -13 21 -75 15 -75 -7 0 -88 53 -95 61 -3 3 -15 11 -28 18 -20
11 -30 8 -70 -15 -26 -15 -49 -30 -52 -33 -10 -12 -50 -31 -50 -24 2 19 26
103 33 109 4 4 7 20 7 35 0 20 -14 39 -60 80 -33 29 -58 54 -57 56 2 1 41 6
88 9 46 3 85 7 86 8 1 1 4 13 6 27 3 13 15 46 27 72 22 47 40 61 40 32z
m-3166 -94 c14 -16 16 -42 14 -212 l-3 -193 -26 -15 c-24 -13 -29 -13 -53 2
l-26 17 0 197 c0 175 2 197 18 209 24 19 56 17 76 -5z m-172 -108 c16 -14 18
-33 18 -154 0 -138 0 -139 -26 -156 -24 -15 -29 -15 -53 -2 -26 15 -26 16 -29
148 -2 107 0 138 13 157 18 28 51 31 77 7z m-154 -120 c15 -21 16 -137 1 -166
-23 -43 -57 -49 -89 -15 -30 32 -29 159 1 189 24 24 65 20 87 -8z m4847 -72
c27 -10 81 -26 118 -35 80 -20 192 -74 257 -125 25 -19 84 -74 132 -122 91
-91 138 -167 173 -279 35 -111 31 -494 -6 -560 -5 -8 -18 -44 -30 -79 -11 -36
-29 -75 -39 -88 -10 -13 -22 -38 -26 -56 -3 -18 -17 -45 -30 -60 -13 -16 -24
-34 -24 -41 0 -7 -11 -23 -25 -36 -14 -13 -39 -47 -57 -74 -43 -71 -170 -190
-252 -238 -117 -69 -106 -13 -107 -525 0 -453 -5 -538 -30 -554 -8 -5 -46 -9
-85 -9 -61 0 -74 3 -95 23 -51 50 -174 227 -207 299 -22 47 -47 82 -88 122
-12 12 -33 44 -48 71 -15 28 -37 64 -49 81 -12 18 -30 45 -39 60 -19 32 -90
132 -108 151 -6 7 -26 39 -44 70 -32 54 -115 167 -151 204 -9 9 -37 22 -63 29
-50 14 -82 35 -82 55 0 6 11 21 25 33 14 12 25 29 25 38 0 18 -18 53 -36 71
-28 28 -74 121 -68 137 5 12 -2 20 -24 28 -22 9 -39 28 -62 72 -35 69 -33 92
10 101 21 5 36 19 54 52 14 25 23 49 20 54 -3 5 -29 9 -59 9 -57 0 -65 -7 -65
-61 0 -21 -7 -32 -28 -43 l-29 -15 4 -713 c3 -605 6 -715 18 -727 8 -8 42 -18
75 -22 33 -4 67 -12 75 -19 19 -15 21 -130 3 -148 -9 -9 -89 -12 -299 -12
-286 0 -288 0 -303 22 -19 27 -21 107 -3 132 9 13 33 20 81 25 75 8 91 20 101
75 4 17 5 191 3 386 l-3 355 -28 53 c-42 81 -117 106 -169 57 -28 -26 -60
-116 -73 -205 -15 -103 -66 -254 -105 -317 -16 -26 -30 -52 -30 -59 0 -6 -21
-40 -46 -74 -43 -58 -48 -62 -78 -57 -34 5 -68 -10 -103 -48 -33 -36 -49 -45
-104 -55 -57 -11 -109 -50 -109 -82 0 -11 15 -28 35 -39 43 -26 44 -41 4 -76
l-32 -28 7 -195 c6 -163 4 -198 -8 -215 -14 -20 -15 -19 -25 8 -13 33 -29 35
-40 5 -12 -32 -3 -48 25 -48 24 0 24 -1 17 -77 -3 -43 -10 -89 -15 -103 l-10
-25 -9 23 c-12 33 -22 27 -47 -27 -17 -38 -22 -67 -22 -129 0 -85 -12 -110
-37 -76 -15 20 -11 68 9 134 12 39 15 154 4 188 -10 32 -62 30 -99 -4 -36 -33
-50 -66 -53 -130 -2 -29 -12 -79 -24 -113 -11 -33 -20 -66 -20 -73 0 -7 -11
-40 -24 -73 -18 -44 -32 -63 -50 -69 -44 -17 -52 5 -22 60 27 51 34 98 15 110
-12 7 -6 65 12 121 8 24 17 33 37 35 35 4 43 43 13 65 -12 8 -21 25 -21 41 0
15 -3 37 -6 50 -9 32 -49 29 -70 -6 -10 -15 -50 -77 -90 -139 -40 -61 -79
-127 -87 -145 -8 -19 -27 -47 -43 -63 -15 -15 -35 -51 -44 -79 l-17 -51 -103
-3 c-57 -1 -107 1 -112 6 -10 10 16 78 32 84 6 2 14 43 18 92 5 67 11 91 24
99 12 8 21 7 33 -3 16 -14 16 -27 -1 -88 -6 -21 -4 -23 17 -20 23 3 24 7 25
82 1 74 -1 81 -25 103 -59 53 -67 63 -79 107 -6 26 -12 63 -13 84 -2 61 -13
87 -36 85 -43 -5 -57 88 -23 145 24 38 24 41 5 66 -13 18 -17 18 -41 6 -23
-13 -27 -13 -40 5 -8 10 -14 29 -14 40 0 41 -30 55 -134 62 -111 7 -121 14
-132 94 -8 57 13 78 91 88 85 12 97 28 106 153 12 155 11 610 -2 666 -5 27
-21 65 -35 83 -49 67 -16 120 86 134 69 10 100 25 106 51 7 26 -10 54 -33 54
-7 0 -27 -9 -42 -21 -16 -12 -37 -19 -47 -17 -18 3 -19 24 -24 470 l-5 466
-23 25 c-13 14 -35 27 -50 31 -15 3 -37 8 -49 12 l-23 6 0 -241 c0 -192 3
-249 15 -278 32 -77 15 -132 -46 -147 -62 -14 -106 24 -145 124 -9 25 -33 64
-53 86 -20 23 -43 53 -53 65 l-16 24 -12 -30 c-18 -47 -29 -56 -52 -44 -19 10
-20 18 -16 78 7 81 3 91 -29 91 -21 0 -24 -4 -20 -23 7 -27 -6 -52 -23 -42 -7
4 -10 19 -6 35 3 19 1 30 -8 33 -31 12 -66 -44 -66 -103 0 -16 -8 -49 -19 -72
-38 -87 -45 -115 -46 -183 -1 -98 5 -107 63 -100 30 4 73 -1 119 -12 68 -17
72 -20 109 -73 35 -51 38 -60 38 -130 1 -65 -3 -81 -27 -122 -15 -27 -32 -48
-37 -48 -5 0 -12 -9 -15 -20 -5 -16 4 -32 33 -64 52 -56 65 -132 37 -211 -18
-52 -59 -105 -82 -105 -8 0 -32 -9 -54 -20 -53 -27 -166 -25 -183 3 -9 15 -6
22 19 42 35 29 53 96 45 169 -7 59 -37 89 -84 84 -30 -3 -31 -5 -38 -63 -5
-45 -3 -69 9 -97 18 -42 12 -83 -14 -92 -29 -11 -45 22 -38 75 8 54 -10 159
-28 171 -6 4 -27 8 -45 8 -42 0 -51 -22 -27 -66 15 -28 16 -38 5 -63 -7 -19
-10 -53 -6 -98 4 -37 9 -141 12 -230 l6 -163 -26 0 c-37 0 -46 26 -46 137 0
187 -17 125 -24 -89 -9 -286 -1 -311 107 -321 47 -4 66 -11 79 -27 25 -31 24
-104 -2 -130 -19 -19 -33 -20 -409 -20 -229 0 -400 4 -415 10 -24 9 -26 14
-26 68 0 75 15 90 99 98 34 4 69 13 77 21 12 12 14 82 14 414 0 458 2 448 -79
500 -56 37 -68 58 -51 88 6 12 8 42 4 74 -5 42 -3 57 11 70 21 21 29 21 50 1
11 -11 14 -24 10 -38 -4 -12 -8 -32 -9 -46 -1 -21 3 -25 29 -25 26 0 30 4 35
35 3 19 3 246 0 503 -5 507 -2 480 -60 493 -14 3 -35 11 -48 17 -31 15 -26 57
8 74 40 20 137 16 187 -8 l44 -21 50 26 c65 33 86 33 159 -4 l59 -30 472 0
471 0 26 28 c38 41 108 49 193 23 63 -20 63 -20 107 -1 63 28 95 25 165 -16
69 -41 81 -41 192 0 55 20 89 26 161 26 98 0 291 -25 310 -41 6 -5 35 -18 65
-28 88 -30 169 -69 203 -96 17 -14 54 -45 83 -68 80 -66 232 -261 246 -317 9
-35 64 -119 83 -127 8 -3 33 0 55 6 l39 12 0 53 c0 62 14 79 58 70 25 -5 31
-3 37 17 8 25 40 58 59 61 6 1 31 2 56 2 39 1 45 4 54 30 11 30 6 36 -66 88
-22 15 -22 17 -7 45 9 15 20 46 24 68 9 55 44 132 61 139 8 3 26 1 40 -4 52
-20 98 -12 130 23 17 17 37 47 44 65 7 18 36 52 65 75 108 87 128 102 154 116
14 7 34 22 44 33 15 15 51 41 111 80 50 32 164 89 196 98 21 6 51 17 67 25 15
8 35 15 43 15 9 0 24 6 35 14 11 7 46 16 78 20 32 3 72 13 90 20 52 23 259 21
322 -3z m-1561 -537 c29 -29 13 -68 -25 -62 -40 5 -37 77 3 78 4 0 14 -7 22
-16z m-2390 -1620 c7 -19 -1 -34 -19 -34 -21 0 -28 19 -15 35 16 19 26 19 34
-1z m1868 -436 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z
m3178 -1009 c0 -85 -3 -108 -12 -103 -79 47 -118 77 -118 89 0 22 62 113 80
118 50 14 50 14 50 -104z m-3660 47 c0 -7 -7 -19 -15 -26 -12 -10 -15 -9 -15
7 0 11 3 23 7 26 11 12 23 8 23 -7z m-1290 -431 c0 -58 3 -105 6 -105 3 0 19
11 36 25 16 13 46 30 68 36 34 10 44 9 87 -12 72 -36 81 -59 86 -229 3 -80 2
-152 -2 -160 -5 -12 -23 -15 -76 -15 l-70 0 -6 120 c-3 66 -9 128 -13 137 -10
22 -59 32 -85 19 -11 -6 -23 -21 -27 -33 -4 -13 -12 -26 -18 -30 -8 -6 -7 -8
2 -8 9 0 12 -27 12 -100 0 -114 2 -113 -100 -108 l-55 3 -3 283 -2 282 80 0
80 0 0 -105z m4181 65 c49 -20 98 -49 99 -60 0 -3 -14 -21 -30 -40 -16 -19
-30 -38 -30 -41 0 -19 -36 -16 -68 6 -27 18 -50 25 -85 25 -42 0 -54 -5 -82
-33 -65 -65 -67 -169 -4 -227 36 -35 139 -57 139 -30 0 6 7 10 15 10 8 0 15 4
15 9 0 5 3 16 6 25 5 13 -2 16 -35 16 l-41 0 0 55 0 55 110 0 110 0 0 -119 c0
-113 -1 -119 -23 -134 -81 -53 -279 -66 -361 -24 -112 59 -170 185 -142 308
13 55 27 84 49 100 5 3 8 9 8 13 -1 3 15 21 35 38 75 66 215 87 315 48z
m-4911 -51 l0 -60 -130 3 -130 3 -6 -32 c-3 -18 -3 -48 0 -65 l6 -33 118 3
119 3 2 -28 c0 -15 2 -43 4 -61 l3 -33 -122 3 c-142 3 -134 11 -134 -108 l0
-72 -77 2 -78 1 -3 258 c-2 178 1 257 8 257 6 0 8 5 5 10 -4 7 67 10 204 10
l211 0 0 -61z m2340 32 c58 -30 97 -66 123 -116 32 -59 31 -195 -1 -248 -13
-20 -28 -34 -34 -31 -6 4 -8 3 -5 -3 7 -11 -32 -50 -73 -76 -33 -20 -178 -38
-295 -36 l-95 1 0 270 0 270 168 -4 c151 -3 171 -6 212 -27z m1736 -18 c49
-60 79 -105 76 -116 -1 -5 2 -6 7 -2 6 3 22 -11 35 -32 40 -60 52 -73 70 -73
14 0 16 17 16 135 l0 135 69 0 c67 0 69 -1 73 -27 7 -40 9 -453 3 -484 -6 -24
-10 -26 -69 -28 l-63 -2 -37 45 c-21 26 -34 51 -30 57 4 7 3 9 -4 5 -6 -3 -21
8 -33 26 -13 18 -34 44 -47 58 -13 14 -31 37 -40 53 -9 15 -24 27 -34 27 -16
0 -18 -12 -18 -133 l0 -133 -72 1 -73 0 -2 259 c-1 142 0 263 3 267 3 5 33 9
68 9 63 0 63 0 102 -47z m-2306 -25 c0 -29 4 -58 8 -65 4 -6 28 -13 52 -15
l45 -3 0 -40 0 -40 -52 -3 -53 -3 0 -70 c0 -88 10 -109 54 -109 32 0 34 -3 45
-46 8 -33 9 -50 2 -57 -17 -17 -129 -21 -172 -7 -63 21 -79 57 -79 178 0 104
-6 120 -42 114 -15 -3 -18 4 -18 42 0 42 2 46 24 46 29 0 36 16 36 79 l0 47
75 2 75 3 0 -53z m1462 -10 l3 -63 48 -3 47 -3 0 -45 0 -46 -38 3 c-56 4 -62
-3 -62 -84 0 -87 8 -102 52 -99 32 2 34 0 45 -39 13 -50 9 -65 -20 -73 -42
-11 -129 -6 -161 9 -54 26 -74 75 -74 182 0 95 -5 115 -25 112 -31 -4 -37 3
-37 41 0 35 3 40 24 40 34 0 45 19 41 70 -2 24 -1 48 1 52 3 4 38 8 79 8 l74
0 3 -62z m-2894 -40 c114 -28 132 -65 132 -268 l0 -150 -80 0 c-73 0 -80 2
-80 20 0 11 -7 20 -15 20 -8 0 -15 -4 -15 -8 0 -13 -63 -32 -104 -32 -60 0
-104 26 -116 69 -18 65 -13 96 22 130 35 34 60 41 158 44 58 2 65 4 68 24 5
33 -30 46 -102 40 -34 -3 -68 -8 -76 -11 -10 -4 -19 3 -27 21 -19 46 -25 73
-16 74 4 0 40 8 78 19 85 22 112 24 173 8z m1053 -8 c44 -16 104 -60 83 -60
-3 0 -2 -6 4 -12 8 -10 17 -239 13 -320 -1 -13 -14 -16 -77 -17 -71 -1 -76 1
-71 19 8 31 -11 34 -44 6 -25 -21 -41 -26 -88 -26 -61 0 -80 10 -114 60 -20
29 -22 85 -4 119 21 43 65 60 155 62 95 3 116 15 82 49 -19 19 -30 21 -83 17
-34 -3 -73 -7 -86 -9 -20 -2 -26 4 -39 39 -9 23 -13 46 -9 49 10 10 155 42
194 43 18 1 56 -8 84 -19z m1428 9 c24 -5 57 -19 72 -30 51 -36 59 -67 59
-236 l0 -153 -80 0 c-73 0 -80 2 -80 20 0 25 -2 25 -42 0 -41 -25 -114 -26
-162 -4 -27 13 -37 26 -46 60 -17 61 -7 105 33 135 26 20 47 25 122 29 83 5
90 7 93 27 5 32 -30 46 -97 40 -31 -3 -65 -8 -76 -12 -17 -5 -23 0 -34 32 -8
21 -16 43 -18 49 -2 6 17 16 44 23 26 7 62 16 78 21 42 11 83 11 134 -1z m856
1 c3 -5 14 -10 24 -10 22 0 85 -42 82 -54 -2 -5 3 -12 11 -17 10 -6 14 -49 16
-172 l3 -164 -75 -1 c-44 -1 -77 2 -77 8 -1 6 -2 15 -3 22 -1 14 -26 23 -26 9
0 -18 -57 -41 -105 -41 -108 0 -165 86 -119 179 20 41 69 61 148 61 68 0 106
8 106 21 0 5 -9 18 -21 30 -18 18 -29 21 -83 16 -33 -3 -70 -8 -81 -12 -17 -5
-23 1 -38 41 -20 55 -17 60 53 70 25 4 54 10 65 15 29 11 113 11 120 -1z"/>
<path d="M7192 7278 c-7 -7 -12 -20 -12 -31 0 -13 6 -18 23 -15 16 2 22 10 22
27 0 28 -15 37 -33 19z"/>
<path d="M7460 7184 c-20 -10 -62 -16 -98 -16 -52 0 -67 -4 -91 -24 -35 -29
-175 -104 -195 -104 -8 0 -29 -12 -46 -26 -19 -17 -47 -28 -74 -31 -37 -5 -45
-10 -57 -37 -8 -17 -25 -36 -37 -42 -13 -7 -41 -28 -61 -48 -47 -46 -77 -47
-86 -5 -4 17 -14 33 -22 36 -26 10 -84 -28 -90 -59 -4 -20 -16 -32 -44 -45
-61 -27 -62 -72 -2 -73 16 0 29 14 50 53 27 49 31 52 66 51 21 -1 41 -4 44 -8
4 -3 -19 -29 -50 -57 -40 -36 -60 -61 -67 -90 -12 -42 -16 -45 -55 -27 -23 10
-28 9 -54 -23 -32 -38 -32 -61 0 -134 16 -34 19 -37 25 -20 11 28 98 115 116
115 19 0 40 -24 67 -77 28 -55 20 -73 -33 -73 -22 0 -59 -5 -80 -10 -26 -5
-46 -5 -59 1 -14 8 -23 7 -36 -5 -26 -24 -39 -20 -62 19 -14 23 -28 35 -43 35
-26 0 -66 -26 -66 -44 0 -6 -11 -34 -24 -62 -14 -27 -31 -65 -39 -85 -10 -23
-25 -40 -46 -48 -17 -8 -31 -19 -31 -25 0 -15 -42 -56 -56 -56 -6 0 -21 11
-34 25 -26 28 -50 33 -50 11 0 -8 -16 -33 -35 -57 -43 -51 -44 -82 -2 -87 42
-5 45 -29 8 -62 -30 -28 -31 -32 -31 -108 0 -89 9 -107 60 -117 86 -16 115
-16 150 2 l35 18 5 165 5 164 40 10 c50 12 58 20 79 82 14 43 23 54 55 68 26
12 49 15 74 11 31 -6 38 -4 42 11 21 69 56 89 132 79 57 -8 76 -29 68 -72 -5
-29 -3 -35 14 -40 11 -3 24 -18 30 -34 6 -18 19 -31 35 -35 48 -12 56 -1 56
73 0 59 -3 70 -27 96 -27 28 -28 29 -9 43 28 20 43 18 59 -8 7 -13 28 -47 45
-76 17 -28 36 -69 42 -90 5 -22 25 -52 44 -70 19 -17 37 -45 41 -62 4 -19 17
-36 34 -45 29 -14 75 -68 126 -145 17 -25 33 -47 36 -50 4 -3 9 -15 13 -26 9
-29 55 -74 75 -74 9 0 33 -9 54 -20 20 -11 43 -20 51 -20 30 0 35 -24 15 -69
-21 -47 -17 -80 11 -106 25 -23 57 -11 64 23 3 15 7 155 8 311 3 248 5 287 20
312 15 24 22 28 48 23 26 -6 30 -4 30 16 0 20 -4 22 -34 16 -49 -9 -60 10 -64
115 -5 111 6 132 75 149 144 35 143 35 159 19 11 -12 13 -22 5 -42 -6 -14 -8
-41 -5 -59 5 -28 0 -38 -30 -67 -28 -27 -36 -43 -36 -71 0 -41 14 -60 45 -60
43 0 45 -13 45 -241 0 -222 2 -235 40 -237 8 0 31 -4 51 -7 35 -7 37 -5 133
98 110 117 119 131 188 270 74 150 87 198 94 344 10 219 -17 339 -104 455 -63
84 -103 119 -196 176 -114 68 -137 79 -218 103 -97 29 -277 35 -328 13z m490
-344 c14 -27 4 -60 -19 -60 -27 0 -44 26 -37 55 8 31 41 34 56 5z m-213 -30
c14 -11 36 -20 48 -20 33 0 50 -25 37 -54 -6 -13 -14 -45 -17 -70 -9 -55 -22
-63 -74 -46 -33 11 -42 10 -65 -5 -26 -17 -27 -17 -81 18 -46 31 -55 42 -60
74 -4 21 -11 49 -16 62 -15 37 4 50 79 54 37 2 80 4 95 5 16 1 40 -7 54 -18z
m-1637 -700 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20
-4 20 -10z"/>
<path d="M4833 6687 c-22 -4 -56 -21 -76 -38 l-37 -30 0 -429 c0 -476 -7 -430
66 -445 43 -8 68 -37 60 -69 -3 -14 -15 -27 -25 -31 -11 -3 -43 -31 -70 -61
-50 -55 -65 -96 -42 -119 5 -6 11 -154 14 -330 5 -321 10 -372 45 -407 17 -17
42 -1 42 26 0 17 10 30 33 43 21 13 37 34 48 64 9 25 23 45 33 47 16 4 16 2 2
-27 -9 -16 -16 -53 -16 -81 l0 -50 -35 0 c-40 0 -55 -21 -34 -46 18 -22 52
-13 66 17 16 34 78 81 101 77 10 -2 18 -14 20 -30 2 -16 15 -36 31 -48 27 -18
29 -19 44 -2 8 9 18 32 22 49 4 19 18 41 34 53 22 15 29 30 34 68 6 51 18 84
72 197 19 39 50 106 69 150 20 46 51 97 75 122 l41 42 0 86 c0 96 -7 108 -80
145 -32 16 -40 26 -40 48 0 42 21 63 69 69 31 4 45 11 50 27 10 26 -3 305 -18
401 -6 39 -18 84 -26 100 -7 17 -26 68 -41 114 -42 129 -124 226 -234 277 -41
19 -238 33 -297 21z m7 -1755 c0 -45 -8 -56 -27 -41 -13 12 -18 61 -6 72 18
19 33 5 33 -31z"/>
<path d="M3246 6642 c-7 -12 8 -57 23 -67 20 -12 12 -43 -11 -47 -21 -3 -23
-9 -24 -68 -1 -36 -5 -149 -9 -252 -9 -207 -7 -218 49 -218 49 -1 128 -25 141
-44 15 -20 8 -92 -11 -121 -32 -45 -9 -71 48 -54 18 5 47 9 65 9 27 0 34 5 39
26 8 31 -7 106 -22 111 -6 2 -16 -15 -23 -37 -11 -37 -40 -57 -42 -27 0 6 -5
44 -11 82 -14 97 -21 324 -10 338 5 7 15 35 22 64 12 45 11 53 -3 68 -19 18
-23 60 -7 70 6 3 15 26 21 50 10 39 15 44 43 47 23 2 32 8 34 26 3 20 -1 22
-37 22 -22 0 -43 5 -46 11 -5 6 -40 10 -94 8 -47 -1 -96 1 -107 5 -12 4 -24 3
-28 -2z"/>
<path d="M7636 6411 c-8 -12 3 -41 15 -41 12 0 12 37 0 45 -5 3 -12 1 -15 -4z"/>
<path d="M6135 6332 c-5 -4 -18 -21 -28 -39 -24 -43 -6 -51 38 -17 38 28 44
48 19 58 -19 7 -17 7 -29 -2z"/>
<path d="M3500 5981 c0 -10 7 -24 15 -31 13 -11 15 -8 15 19 0 21 -5 31 -15
31 -8 0 -15 -8 -15 -19z"/>
<path d="M5665 5930 c-14 -15 -16 -23 -7 -32 13 -13 42 6 42 28 0 22 -17 24
-35 4z"/>
<path d="M7622 5264 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
-16z"/>
<path d="M5251 4621 c-13 -23 -14 -51 -3 -51 17 1 32 17 34 39 4 30 -17 38
-31 12z"/>
<path d="M4823 4487 c-2 -4 -6 -33 -7 -63 -3 -67 -21 -91 -30 -41 -8 42 -29
63 -43 45 -5 -7 -16 -29 -23 -48 -7 -19 -19 -42 -27 -51 -18 -21 -17 -35 9
-88 l21 -44 -20 -37 c-19 -31 -19 -38 -7 -60 19 -35 17 -57 -6 -90 -28 -40
-42 -81 -35 -107 7 -31 23 -29 38 5 6 15 29 50 50 77 53 71 72 141 56 206 -16
61 -6 82 42 89 l34 5 -4 68 c-4 85 15 122 59 113 25 -4 40 6 40 29 0 7 -142
-1 -147 -8z"/>
<path d="M5177 4353 c-13 -12 -7 -53 7 -53 20 0 39 27 32 45 -6 15 -28 20 -39
8z"/>
<path d="M4557 4194 c-23 -121 -16 -164 27 -164 34 0 39 11 14 37 -17 18 -22
35 -21 76 1 62 -12 95 -20 51z"/>
<path d="M5102 4157 c-28 -30 -28 -37 1 -37 25 0 57 28 57 48 0 20 -35 13 -58
-11z"/>
<path d="M5224 4156 c-8 -20 -1 -47 11 -49 6 -1 11 13 13 31 3 32 -14 45 -24
18z"/>
<path d="M5257 3343 c-11 -18 -9 -263 2 -276 7 -9 28 -11 71 -7 71 7 93 25
113 90 12 39 12 53 0 95 -21 74 -50 98 -121 103 -33 2 -62 0 -65 -5z"/>
<path d="M7423 3360 c0 -47 2 -66 4 -42 2 23 2 61 0 85 -2 23 -4 4 -4 -43z"/>
<path d="M7531 3364 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7531 3154 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3358 3112 c-33 -4 -46 -38 -28 -72 10 -19 18 -22 52 -17 47 6 59 20
56 61 -3 31 -16 35 -80 28z"/>
<path d="M3191 3054 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4360 3095 c-19 -23 -4 -64 27 -71 37 -10 90 20 87 49 -1 12 -3 25
-3 30 -2 14 -98 8 -111 -8z"/>
<path d="M5861 3110 c-52 -6 -67 -24 -52 -62 9 -23 17 -28 45 -28 42 0 66 22
66 60 0 35 -4 37 -59 30z"/>
<path d="M6671 3092 c-14 -25 -3 -60 20 -67 28 -9 76 4 83 21 3 9 10 13 15 10
6 -4 6 5 2 21 -7 25 -12 28 -59 31 -41 2 -53 0 -61 -16z"/>
<path d="M6990 10261 c19 -11 39 -20 45 -21 16 0 -28 27 -55 34 -19 5 -16 2
10 -13z"/>
<path d="M3580 10190 c-12 -8 -10 -10 8 -10 13 0 20 4 17 10 -7 12 -6 12 -25
0z"/>
<path d="M7200 10190 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3465 10140 c-8 -13 5 -13 25 0 13 8 13 10 -2 10 -9 0 -20 -4 -23
-10z"/>
<path d="M7300 10146 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8
0 -15 -2 -15 -4z"/>
<path d="M7379 10114 c19 -8 36 -12 39 -9 5 5 -43 25 -61 25 -7 -1 3 -7 22
-16z"/>
<path d="M7467 10079 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M3268 10053 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7550 10040 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7590 10020 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M3140 9994 l-25 -15 25 7 c28 7 35 12 29 18 -2 2 -15 -2 -29 -10z"/>
<path d="M7635 10000 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7665 9980 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z"/>
<path d="M3080 9970 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M7724 9955 c11 -8 27 -15 35 -15 9 0 3 7 -13 15 -36 19 -47 19 -22 0z"/>
<path d="M2990 9919 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2917 9883 c-26 -14 -46 -27 -44 -30 4 -3 94 41 106 52 12 12 -18 2
-62 -22z"/>
<path d="M7825 9900 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7880 9870 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M2827 9831 c-35 -23 -43 -31 -29 -31 6 0 24 11 39 25 33 28 29 31
-10 6z"/>
<path d="M7940 9841 c0 -5 7 -11 15 -15 8 -3 15 -1 15 3 0 5 -7 11 -15 15 -8
3 -15 1 -15 -3z"/>
<path d="M7985 9810 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M2750 9790 c-8 -5 -12 -11 -9 -14 2 -3 11 -1 19 4 8 5 12 11 9 14 -2
3 -11 1 -19 -4z"/>
<path d="M8020 9795 c0 -2 10 -10 23 -16 20 -11 21 -11 8 4 -13 16 -31 23 -31
12z"/>
<path d="M2674 9734 c-18 -14 -18 -15 4 -4 12 6 22 13 22 15 0 8 -5 6 -26 -11z"/>
<path d="M8112 9734 c10 -10 20 -15 24 -12 3 4 -5 12 -18 18 -22 12 -22 12 -6
-6z"/>
<path d="M2625 9710 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M8150 9709 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2590 9685 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"/>
<path d="M8190 9691 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M2434 9574 c-18 -14 -18 -15 4 -4 12 6 22 13 22 15 0 8 -5 6 -26 -11z"/>
<path d="M8355 9570 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M2350 9519 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8430 9520 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2294 9475 c-10 -8 -14 -14 -9 -14 6 0 19 6 29 14 11 8 15 15 10 15
-6 0 -19 -7 -30 -15z"/>
<path d="M8516 9456 c10 -8 21 -12 24 -10 5 6 -21 24 -34 24 -6 0 -1 -6 10
-14z"/>
<path d="M2195 9400 c-10 -11 -15 -20 -11 -20 4 0 16 9 28 20 12 11 17 20 11
20 -5 0 -18 -9 -28 -20z"/>
<path d="M8588 9398 c6 -7 25 -21 44 -32 l33 -19 -35 31 c-35 31 -62 43 -42
20z"/>
<path d="M2105 9329 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z"/>
<path d="M8754 9261 c20 -22 26 -25 26 -13 0 4 -10 13 -22 21 -23 14 -23 14
-4 -8z"/>
<path d="M2025 9260 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M8790 9230 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1921 9165 c-25 -25 -43 -46 -40 -48 2 -2 27 18 54 45 28 26 46 47
40 47 -5 0 -30 -20 -54 -44z"/>
<path d="M8845 9180 c16 -16 30 -28 32 -26 6 5 -42 56 -53 56 -4 0 5 -13 21
-30z"/>
<path d="M1845 9090 c-10 -11 -15 -20 -11 -20 11 0 39 30 34 36 -3 2 -13 -5
-23 -16z"/>
<path d="M8952 9080 c7 -11 17 -20 22 -20 5 0 1 9 -9 20 -10 11 -20 20 -22 20
-2 0 2 -9 9 -20z"/>
<path d="M1775 9020 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M9010 9026 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M1723 8972 c-18 -20 -30 -38 -28 -40 5 -6 65 59 65 71 0 10 -2 9 -37
-31z"/>
<path d="M9060 8975 c24 -31 61 -68 65 -63 3 2 -14 22 -37 44 -23 21 -36 30
-28 19z"/>
<path d="M1631 8878 c-24 -27 -42 -50 -39 -53 3 -3 25 18 50 45 25 28 43 52
39 53 -3 2 -26 -18 -50 -45z"/>
<path d="M9156 8877 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M1542 8777 c-12 -13 -22 -27 -22 -32 0 -4 11 4 25 19 23 25 30 36 22
36 -2 0 -13 -10 -25 -23z"/>
<path d="M9255 8760 c10 -11 22 -20 27 -20 4 0 -1 9 -12 20 -11 11 -23 20 -27
20 -3 0 2 -9 12 -20z"/>
<path d="M1490 8719 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M1460 8679 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M1394 8598 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M9395 8597 c10 -15 20 -25 23 -22 3 3 -5 16 -17 28 l-23 22 17 -28z"/>
<path d="M9455 8520 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1320 8509 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M9480 8479 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M1270 8439 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M9515 8440 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1240 8400 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M9570 8360 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1160 8285 c-7 -9 -11 -17 -9 -20 3 -2 10 5 17 15 14 24 10 26 -8 5z"/>
<path d="M9640 8271 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M9700 8171 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M1071 8149 c-7 -12 -10 -23 -8 -26 3 -3 11 5 17 17 15 28 8 35 -9 9z"/>
<path d="M9756 8083 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M950 7954 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M9827 7959 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M9870 7895 c0 -15 18 -40 24 -34 3 3 -1 14 -9 25 -8 10 -14 15 -15 9z"/>
<path d="M906 7873 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M820 7709 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M786 7643 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M771 7604 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M10021 7604 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M736 7523 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M10075 7480 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M10091 7453 c0 -4 4 -17 9 -28 8 -19 9 -19 9 2 1 12 -3 25 -9 28 -5
3 -10 3 -9 -2z"/>
<path d="M677 7403 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M10125 7350 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M645 7319 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M10140 7321 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M10170 7251 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M607 7231 c-4 -17 -3 -21 5 -13 5 5 8 16 6 23 -3 8 -7 3 -11 -10z"/>
<path d="M587 7173 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4 20z"/>
<path d="M556 7091 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M10262 7010 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M526 7004 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"/>
<path d="M512 6950 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M10277 6963 c-3 -5 -1 -17 4 -28 6 -17 7 -16 6 8 -1 28 -3 32 -10 20z"/>
<path d="M10290 6893 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M475 6841 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M10333 6766 c4 -10 7 -24 7 -32 0 -8 4 -14 9 -14 11 0 1 49 -12 57
-7 4 -8 0 -4 -11z"/>
<path d="M451 6763 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30 -3
9 -5 9 -5 0z"/>
<path d="M431 6684 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M422 6640 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M10366 6642 c-2 -4 -1 -14 5 -22 7 -12 9 -10 9 8 0 23 -5 28 -14 14z"/>
<path d="M10382 6570 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M382 6465 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M10422 6420 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M371 6414 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M361 6364 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M342 6245 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M10452 6245 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M332 6155 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M10460 6141 c0 -11 4 -22 9 -25 4 -3 6 6 3 19 -5 30 -12 33 -12 6z"/>
<path d="M310 6095 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25 -5
8 -10 11 -10 5z"/>
<path d="M10475 6080 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M10502 5870 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M291 5824 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M272 5540 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M10522 5535 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M10521 5344 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M10521 5264 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M272 5125 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M10510 5098 c0 -19 2 -20 10 -8 13 19 13 30 0 30 -5 0 -10 -10 -10
-22z"/>
<path d="M10502 4935 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M10461 4654 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M372 4385 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M10422 4380 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M10376 4204 c-8 -22 -8 -54 2 -54 4 0 7 16 8 35 1 39 -1 42 -10 19z"/>
<path d="M421 4164 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M451 4044 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M10341 4044 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M10317 3973 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M501 3874 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M535 3750 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"/>
<path d="M10237 3723 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M10206 3644 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"/>
<path d="M600 3575 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25 -5
8 -10 11 -10 5z"/>
<path d="M670 3408 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z"/>
<path d="M711 3314 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M741 3250 c0 -8 6 -24 14 -34 19 -25 19 -9 0 24 -8 14 -14 19 -14 10z"/>
<path d="M10046 3243 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M10020 3189 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M10005 3159 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M820 3081 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3
-10 2 -10 -4z"/>
<path d="M850 3020 c6 -11 13 -20 16 -20 2 0 0 9 -6 20 -6 11 -13 20 -16 20
-2 0 0 -9 6 -20z"/>
<path d="M9940 3029 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M9925 2999 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M900 2916 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M931 2874 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9826 2825 c-9 -14 -13 -25 -9 -25 10 0 36 40 30 46 -2 3 -12 -7 -21
-21z"/>
<path d="M1007 2745 c9 -19 18 -33 21 -31 4 5 -26 66 -33 66 -3 0 3 -16 12
-35z"/>
<path d="M1047 2680 c10 -11 19 -20 21 -20 1 0 -2 9 -8 20 -6 11 -15 20 -20
20 -6 0 -2 -9 7 -20z"/>
<path d="M1085 2623 c8 -18 18 -33 20 -33 10 0 4 16 -16 40 l-20 25 16 -32z"/>
<path d="M9706 2623 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M9636 2524 c-10 -14 -16 -28 -13 -30 2 -3 12 7 21 22 10 14 16 28 13
30 -2 3 -12 -7 -21 -22z"/>
<path d="M1156 2515 c9 -14 19 -24 21 -21 6 6 -20 46 -30 46 -4 0 0 -11 9 -25z"/>
<path d="M9577 2443 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M9542 2390 c-7 -11 -8 -20 -3 -20 9 0 24 30 18 36 -1 2 -8 -6 -15
-16z"/>
<path d="M1245 2380 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M9475 2304 c-9 -14 -14 -28 -12 -31 3 -3 13 9 22 26 19 38 14 41 -10
5z"/>
<path d="M1320 2280 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1353 2251 c0 -11 5 -18 9 -15 4 3 5 11 1 19 -7 21 -12 19 -10 -4z"/>
<path d="M9396 2197 c-36 -48 -32 -58 4 -12 17 21 30 39 30 41 0 11 -12 1 -34
-29z"/>
<path d="M1395 2190 c10 -11 20 -20 22 -20 2 0 -1 9 -7 20 -6 11 -16 20 -22
20 -6 0 -3 -9 7 -20z"/>
<path d="M9330 2119 c-36 -46 -38 -60 -2 -19 27 30 39 50 30 50 -2 0 -14 -14
-28 -31z"/>
<path d="M1450 2120 c11 -11 23 -20 27 -20 3 0 -2 9 -12 20 -10 11 -22 20 -27
20 -4 0 1 -9 12 -20z"/>
<path d="M1485 2080 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M9270 2045 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"/>
<path d="M1520 2042 c0 -5 7 -15 15 -22 8 -7 15 -8 15 -2 0 5 -7 15 -15 22 -8
7 -15 8 -15 2z"/>
<path d="M9231 1997 c-13 -21 -12 -21 5 -5 10 10 16 20 13 22 -3 3 -11 -5 -18
-17z"/>
<path d="M1560 1998 c0 -10 40 -51 45 -46 3 2 -6 15 -20 28 -14 13 -25 21 -25
18z"/>
<path d="M9181 1947 c-13 -21 -12 -21 5 -5 10 10 16 20 13 22 -3 3 -11 -5 -18
-17z"/>
<path d="M1630 1923 c0 -5 10 -18 23 -29 l22 -19 -20 25 c-11 14 -21 27 -22
29 -2 2 -3 0 -3 -6z"/>
<path d="M1700 1846 c0 -11 30 -39 36 -34 2 3 -5 13 -16 23 -11 10 -20 15 -20
11z"/>
<path d="M9062 1819 c-19 -17 -32 -32 -30 -35 3 -2 20 12 38 31 42 43 37 45
-8 4z"/>
<path d="M8977 1738 c-40 -43 -28 -45 13 -3 19 19 30 34 24 34 -5 -1 -22 -15
-37 -31z"/>
<path d="M8917 1683 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M8836 1604 c-27 -24 -47 -46 -44 -49 2 -3 26 16 52 41 61 60 55 66
-8 8z"/>
<path d="M1950 1605 c0 -10 53 -55 59 -50 2 3 2 6 0 8 -2 1 -16 13 -31 26 -16
14 -28 21 -28 16z"/>
<path d="M2032 1530 c7 -11 17 -20 22 -20 5 0 1 9 -9 20 -10 11 -20 20 -22 20
-2 0 2 -9 9 -20z"/>
<path d="M8745 1520 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M8710 1489 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2116 1458 c10 -14 21 -24 23 -22 3 3 -6 15 -18 27 l-24 22 19 -27z"/>
<path d="M8685 1472 c-5 -4 -19 -18 -30 -32 -16 -21 -14 -20 13 4 29 27 37 36
30 36 -2 0 -7 -4 -13 -8z"/>
<path d="M2183 1400 c14 -11 29 -20 34 -20 10 1 -39 39 -51 40 -5 0 3 -9 17
-20z"/>
<path d="M8593 1394 c-20 -18 -22 -22 -7 -15 21 10 52 41 41 41 -3 0 -19 -12
-34 -26z"/>
<path d="M2236 1362 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6 -4
-9 -8z"/>
<path d="M2270 1330 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8510 1329 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2330 1286 c0 -2 25 -20 56 -40 31 -21 53 -33 50 -27 -7 10 -93 71
-102 71 -2 0 -4 -2 -4 -4z"/>
<path d="M8440 1279 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8383 1234 l-28 -26 33 22 c29 20 39 30 28 30 -3 0 -18 -12 -33 -26z"/>
<path d="M8330 1195 c-11 -13 -10 -14 4 -9 9 3 16 10 16 15 0 13 -6 11 -20 -6z"/>
<path d="M2490 1177 c0 -3 12 -12 26 -22 15 -9 23 -12 20 -6 -7 11 -46 35 -46
28z"/>
<path d="M8270 1160 c-14 -11 -20 -20 -15 -20 6 0 21 9 35 20 14 11 21 20 15
20 -5 0 -21 -9 -35 -20z"/>
<path d="M8179 1093 c-13 -15 -12 -15 9 -4 23 12 28 21 13 21 -5 0 -15 -7 -22
-17z"/>
<path d="M2610 1089 c29 -19 70 -39 70 -34 0 7 -63 45 -75 45 -5 0 -3 -5 5
-11z"/>
<path d="M8130 1070 c-8 -5 -12 -11 -10 -14 3 -2 14 2 24 10 22 15 10 19 -14
4z"/>
<path d="M8044 1014 c-19 -15 -19 -15 1 -6 11 5 27 12 35 15 13 5 13 6 -1 6
-8 1 -24 -6 -35 -15z"/>
<path d="M2752 1004 c10 -10 20 -15 24 -12 3 4 -5 12 -18 18 -22 12 -22 12 -6
-6z"/>
<path d="M2825 960 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6 0
-7 -4 -4 -10z"/>
<path d="M7960 959 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2978 873 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7795 870 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M3020 850 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M7760 850 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M3066 832 c12 -11 64 -33 64 -27 0 5 -56 35 -67 35 -4 0 -3 -4 3 -8z"/>
<path d="M7633 792 c-18 -9 -33 -20 -33 -25 0 -9 56 19 65 33 8 12 7 12 -32
-8z"/>
<path d="M3140 796 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M3185 770 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6 0
-7 -4 -4 -10z"/>
<path d="M7545 750 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3307 719 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M7435 700 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3428 663 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3628 588 c5 -5 16 -8 23 -6 8 3 3 7 -10 11 -17 4 -21 3 -13 -5z"/>
<path d="M7150 590 c-13 -9 -13 -10 0 -10 8 0 22 5 30 10 13 9 13 10 0 10 -8
0 -22 -5 -30 -10z"/>
<path d="M7084 565 c-10 -8 -14 -14 -9 -14 6 0 19 6 29 14 11 8 15 15 10 15
-6 0 -19 -7 -30 -15z"/>
<path d="M3705 560 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6 0
-7 -4 -4 -10z"/>
<path d="M3868 503 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6908 503 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4021 456 c2 -2 18 -6 34 -10 22 -5 26 -4 15 4 -14 9 -59 15 -49 6z"/>
<path d="M6730 450 c-18 -12 -2 -12 25 0 13 6 15 9 5 9 -8 0 -22 -4 -30 -9z"/>
<path d="M4098 438 c5 -5 16 -8 23 -6 8 3 3 7 -10 11 -17 4 -21 3 -13 -5z"/>
<path d="M6678 433 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4158 423 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4208 413 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6588 413 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4373 373 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M6393 373 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M6218 343 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6138 333 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4710 320 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5 0
-17 -5 -25 -10z"/>
<path d="M6070 320 c8 -5 22 -9 30 -9 10 0 8 3 -5 9 -27 12 -43 12 -25 0z"/>
<path d="M4828 303 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5838 293 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5203 273 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>

</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
